import './index.css';

import React from 'react';

import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import CacheBuster from 'react-cache-buster';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import packageInfo from '../package.json';
import App from './App';
import SnackbarCloseButton from './components/SnakbarCloseButton';
import theme from './components/Theme/theme';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <CacheBuster currentVersion={packageInfo.version}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarProvider
              maxSnack={5}
              action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
            >
              <App />
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </CacheBuster>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
