/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { fetchGroups } from '../../../axiosClient/authApi';
import {
  createUmbrellaProject,
  deleteProject,
  editContract,
  getUmbrellaProjects,
} from '../../../axiosClient/contractsApi';
import CustomChip from '../../../components/CustomChip';
import RISelectComponent from '../../../components/SelectComponent';
import { getSessionStorage } from '../../../hooks/authentication';
import { IContract } from '../../../interfaces/IContract';
import { IOptions } from '../../../interfaces/IOptions';
import { contractApi } from '../../../redux/services/contractApi';
import { useAppDispatch } from '../../../redux/store';

interface Props {
  contractData: IContract;
}

const ContractDetails: React.FC<Props> = ({ contractData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const methods = useForm();
  const dispatch = useAppDispatch();

  const { control, handleSubmit, resetField, setValue } = methods;

  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(true);
  const [editProject, setEditProject] = useState<boolean>(false);
  const [editGroup, setEditGroup] = useState<boolean>(false);

  const user_data = React.useMemo(() => getSessionStorage('user_profile'), []);

  const isOwner = contractData?.created_by?.id === user_data?.id;

  const userIsCoOwner = useMemo(
    () => contractData?.owners?.find((owner: any) => owner?.id === user_data?.id),
    [contractData?.owners, user_data]
  );

  const userCanDoAction = useMemo(
    () => isOwner || userIsCoOwner,
    [isOwner, userIsCoOwner]
  );

  const { data: groupListData, isLoading: groupLoading } = useQuery({
    queryKey: ['GroupList'],
    queryFn: fetchGroups,
    select: (response) => response?.results,
  });

  useEffect(() => {
    if (contractData) {
      const getProjectIds = contractData?.projects?.map((item: any) => item?.id) || [];
      const filterGroups = contractData?.groups?.map((item: any) => item?.id) || [];
      setValue('groups', filterGroups);
      setValue('projects', getProjectIds[0]);
    }
  }, [contractData, groupListData]);

  const { data: projects, isLoading: projectLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: getUmbrellaProjects,
    select: (response) => {
      return response?.results;
    },
  });

  const { mutate: createProjectMutation } = useMutation({
    mutationKey: ['add_project'],
    mutationFn: createUmbrellaProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
  });

  const { mutate: deleteProjectMutation } = useMutation({
    mutationKey: ['delete-project'],
    mutationFn: deleteProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
      enqueueSnackbar('Project deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: updateContract, isPending: editContractLoading } = useMutation({
    mutationFn: editContract,
    onSuccess: () => {
      enqueueSnackbar('Contract updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      dispatch(
        contractApi.endpoints.getContract.initiate(contractData?.id || '', {
          forceRefetch: true,
        })
      );
      setEditProject(false);
      setEditGroup(false);
    },
    onError: (error: any) => {
      const responseData =
        error?.response?.data?.__all__?.[0] || 'Failed to update Contract name!';
      enqueueSnackbar(responseData, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleEditProjects = () => {
    if (userCanDoAction) {
      setEditProject(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleEditGroups = () => {
    if (userCanDoAction) {
      setEditGroup(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const onSubmit = (data: any) => {
    data.projects = [data.projects];
    const payload = {
      id: contractData?.id,
      body: data,
    };
    if (data?.groups?.length > 0) {
      updateContract(payload);
    } else {
      enqueueSnackbar('Please select any one group', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  return (
    <React.Fragment>
      <Accordion
        sx={{
          padding: 0,
        }}
        className="according-class"
        expanded={accordionExpanded}
        onChange={() => setAccordionExpanded(!accordionExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Contract Details
        </AccordionSummary>
        <AccordionDetails>
          <Stack width="100%" spacing={2}>
            <Stack
              spacing={1}
              style={{
                background: '#FFECF1',
                borderRadius: '10px',
                padding: '10px',
              }}
            >
              <Typography fontWeight={600}>Contract Type</Typography>
              <Box>
                <Chip
                  label={contractData?.contract_type?.display_name}
                  style={{ background: '#C4DBFF', cursor: 'pointer' }}
                />
              </Box>
            </Stack>

            {editGroup ? (
              <Box
                style={{
                  background: '#FFECF1',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={2} width="100%">
                    <Typography fontWeight={600}>Edit Group</Typography>
                    <RISelectComponent
                      name="groups"
                      control={control}
                      label="Groups"
                      options={groupListData}
                      loading={groupLoading}
                      isMultiselect={true}
                      valueKey="id"
                      renderCustomComponent={(value: any, props) => (
                        <CustomChip
                          {...props}
                          icon={
                            <GroupWorkOutlinedIcon
                              style={{
                                color: '#6D264C',
                              }}
                            />
                          }
                          label={value?.name}
                          style={{
                            background: '#88305F24',
                          }}
                        />
                      )}
                    />
                    <Stack direction="row">
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        startIcon={<CheckIcon />}
                        loading={editContractLoading}
                      >
                        Save
                      </LoadingButton>
                      <Button
                        variant="outlined"
                        onClick={() => setEditGroup(false)}
                        startIcon={<CloseIcon />}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Box>
            ) : (
              <Stack
                spacing={1}
                sx={{
                  background: '#FFECF1',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <Stack width="100%" justifyContent="space-between" direction="row">
                  <Typography fontWeight={600}>Group</Typography>
                  <Button
                    variant="text"
                    startIcon={<ModeEditOutlineOutlinedIcon />}
                    style={{ padding: 0 }}
                    onClick={handleEditGroups}
                  >
                    Edit
                  </Button>
                </Stack>
                <Stack direction="row" spacing={2}>
                  {contractData?.groups?.map((item: IOptions, index: number) => (
                    <Chip
                      key={index}
                      label={item.name}
                      style={{ background: '#DCBDE7', cursor: 'pointer' }}
                    />
                  ))}
                </Stack>
              </Stack>
            )}
            {editProject ? (
              <Box
                style={{
                  background: '#FFECF1',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={2} width="100%">
                    <Typography fontWeight={600}>Edit Project</Typography>
                    <RISelectComponent
                      control={control}
                      name="projects"
                      required
                      options={projects}
                      label="Project Name"
                      loading={projectLoading}
                      canCreateNew
                      addNewValue={(value) => createProjectMutation({ name: value })}
                      renderAction={(value) => (
                        <Stack direction="row">
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              setTimeout(() => {
                                resetField('projects');
                              }, 0);
                              deleteProjectMutation(value.id);
                              setTimeout(() => {
                                resetField('projects');
                              }, 0);
                            }}
                            color="error"
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Stack>
                      )}
                    />

                    <Stack
                      direction="row"
                      marginTop={3}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction="row">
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          startIcon={<CheckIcon />}
                          loading={editContractLoading}
                        >
                          Save
                        </LoadingButton>
                        <Button
                          variant="outlined"
                          onClick={() => setEditProject(false)}
                          startIcon={<CloseIcon />}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </form>
              </Box>
            ) : (
              <Stack
                spacing={1}
                style={{
                  background: '#FFECF1',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <Stack width="100%" justifyContent="space-between" direction="row">
                  <Typography fontWeight={600}>Project</Typography>
                  <Button
                    variant="text"
                    startIcon={<ModeEditOutlineOutlinedIcon />}
                    style={{ padding: 0 }}
                    onClick={handleEditProjects}
                  >
                    Edit
                  </Button>
                </Stack>

                <Stack direction="row" spacing={2}>
                  {contractData?.projects?.map((item: IOptions, index: number) => (
                    <Chip
                      key={index}
                      label={item?.name}
                      style={{ background: '#CDE7BD', cursor: 'pointer' }}
                    />
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default ContractDetails;
