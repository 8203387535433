/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';

import WebViewer from '@pdftron/webviewer';

import { APRYSE_LICENSE_KEY } from '../../Configuration/global';

const DocxViewerComponent = (props: any) => {
  const documentContainer = useRef<HTMLDivElement>(null);
  const { fileLink, setInstance, setDocumentLoading } = props;

  useEffect(() => {
    if (fileLink && documentContainer.current) {
      setDocumentLoading?.(true);
      WebViewer.WebComponent(
        {
          path: '/webviewer',
          fullAPI: true,
          initialDoc: fileLink,
          css: '/styles/webviewer.css',
          disabledElements: [
            'header',
            'toolsHeader',
            'linkButton',
            'annotationCommentButton',
            'contextMenuPopup',
          ],
          licenseKey: APRYSE_LICENSE_KEY,
        },
        documentContainer.current
      ).then((instance) => {
        const { UI, Core } = instance;
        setInstance(instance);
        const { documentViewer, Annotations } = Core;

        UI.annotationPopup.update([{ type: 'ac' }]);

        const callback = () => {
          documentViewer.removeEventListener('documentLoaded', callback);
        };

        documentViewer.addEventListener('documentLoaded', async () => {
          const FitMode = UI.FitMode;
          UI.setFitMode(FitMode.FitWidth);
          setDocumentLoading?.(false);
          callback();
        });
        Annotations.setCustomCreateSignHereElementHandler(function (
          tool,
          { originalCreateSignHereElement }
        ) {
          const signHereElement = originalCreateSignHereElement(tool);
          signHereElement.innerHTML = 'Click Here To Sign';
          return signHereElement;
        });
      });
    }
  }, [fileLink, documentContainer, setInstance, setDocumentLoading]);

  return <div ref={documentContainer} id="document-viewer" style={{ height: '90vh' }} />;
};

export default DocxViewerComponent;
