/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridRowId } from '@mui/x-data-grid';
import * as changesets from 'json-diff-ts';

import { authApi } from './authApi';
import { IContract } from '../interfaces/IContract';
import { IContractType } from '../interfaces/IContractType';
import { IObject } from '../interfaces/IObject';
import { IOptions } from '../interfaces/IOptions';
import { IResponse } from '../interfaces/IResponse';

export const deleteContract = async (ids: GridRowId[]) => {
  const data = { ids: ids };
  return await authApi.post('contracts/bulk-delete/', data);
};

export const fetchContractById = async (
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return await authApi.get(`admin/contract_type/${id}/`);
};

export const getContracts = async (params: string): Promise<IResponse<IContract[]>> => {
  return await authApi.get(`contracts/${params}`);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addContract = async (payload: any) => {
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });
  return await authApi.post('contracts/', formData);
};

export const getDocument = async (payload: IObject) => {
  return await authApi.post('azure/presigned-url/', payload);
};

export const uploadDoc = async (payload: IObject) => {
  return await authApi.post('azure/presigned-url/', payload);
};

export const getContractType = async (): Promise<IResponse<IContractType[]>> => {
  return await authApi.get('admin/contract_type/');
};

export const getUmbrellaProjects = async (): Promise<IResponse<IOptions[]>> => {
  return await authApi.get('umbrella/projects/');
};

export const createUmbrellaProject = async (payload: { name: string }) => {
  return await authApi.post('umbrella/projects/', payload);
};

export const deleteProject = async (id: string) => {
  return await authApi.delete(`umbrella/projects/${id}/`);
};
export const getContractSections = async (id: string) => {
  return await authApi.get(`contracts/clause_types/${id}/`);
};

export const getInsights = async (id: string) => {
  return await authApi.get(`contracts/insights/${id}/`);
};

export const updateContract = async (payload: {
  contractId: string;
  id: string;
  diff: any;
  updatedClauseData: any;
  type: string;
}) => {
  const flatChanges = changesets.flattenChangeset(payload.diff);
  const body = {
    edited_content: {
      update: flatChanges,
    },
    copy_edited_content: payload.updatedClauseData,
  };
  const esPayload = {
    index: payload.type.toLowerCase(),
    contract_id: payload.contractId,
    document: payload.updatedClauseData,
  };
  authApi.post('contracts/update-es/', esPayload);
  const response = await authApi.patch(`contracts/insights/${payload.id}/`, body);
  return {
    id: payload.id,
    response,
  };
};

export const getDownloadUrl = async (payload: IObject) => {
  return await authApi.post('contracts/azure/presigned-url/', payload);
};

export const editContract = async (payload: any) => {
  const { id, body } = payload;
  return await authApi.patch(`contracts/${id}/`, body);
};
