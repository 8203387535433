/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import EditDate from './EditDate';
import EditLinkAmountPhrase from './EditLinkAmountPhrase';
import EditLinkDurationPhrase from './EditLinkDurationPhrase';
import EditPhrase from './EditPhrase';
import EditPresentSentence from './EditPresentSentence';

interface Props {
  clauseId: string;
  type: string;
  clauseName: string;
  clauseDisplayName: string;
  savedInsight: any;
  onClose: VoidFunction;
  updatedClauseData: any;
  updateContract: any;
  rawContent: any;
  parentClauseType?: any;
  clauseData?: any;
  sectionType: string;
  buttonLoading: boolean;
}

const EditFeature: FC<Props> = ({
  type,
  clauseName,
  savedInsight,
  onClose,
  clauseId,
  updateContract,
  updatedClauseData,
  clauseData,
  parentClauseType,
  clauseDisplayName,
  rawContent,
  sectionType,
  buttonLoading,
}) => {
  switch (type?.toLowerCase()) {
    case 'para':
      return <div></div>;
    case 'sentence':
      return (
        <EditPresentSentence
          clauseName={clauseName}
          clauseDisplayName={clauseDisplayName}
          savedPresentData={savedInsight}
          onClose={onClose}
          id={clauseId}
          updateContract={updateContract}
          updatedClauseData={updatedClauseData}
          rawContent={rawContent}
          clauseData={clauseData}
          parentClauseType={parentClauseType}
          sectionType={sectionType}
          buttonLoading={buttonLoading}
        />
      );
    case 'phrase': {
      return (
        <EditPhrase
          clauseName={clauseName}
          clauseDisplayName={clauseDisplayName}
          savedInsight={savedInsight}
          onClose={onClose}
          id={clauseId}
          updateContract={updateContract}
          updatedClauseData={updatedClauseData}
          rawContent={rawContent}
          clauseData={clauseData}
          parentClauseType={parentClauseType}
          sectionType={sectionType}
          buttonLoading={buttonLoading}
        />
      );
    }
    case 'duration': {
      return (
        <EditLinkDurationPhrase
          clauseName={clauseName}
          clauseDisplayName={clauseDisplayName}
          savedInsight={savedInsight}
          onClose={onClose}
          id={clauseId}
          updateContract={updateContract}
          updatedClauseData={updatedClauseData}
          rawContent={rawContent}
          clauseData={clauseData}
          parentClauseType={parentClauseType}
          sectionType={sectionType}
          buttonLoading={buttonLoading}
        />
      );
    }
    case 'amount': {
      return (
        <EditLinkAmountPhrase
          clauseName={clauseName}
          clauseDisplayName={clauseDisplayName}
          savedInsight={savedInsight}
          onClose={onClose}
          id={clauseId}
          updateContract={updateContract}
          updatedClauseData={updatedClauseData}
          rawContent={rawContent}
          clauseData={clauseData}
          parentClauseType={parentClauseType}
          sectionType={sectionType}
          buttonLoading={buttonLoading}
        />
      );
    }
    case 'date': {
      return (
        <EditDate
          clauseName={clauseName}
          clauseDisplayName={clauseDisplayName}
          savedInsight={savedInsight}
          onClose={onClose}
          id={clauseId}
          updateContract={updateContract}
          updatedClauseData={updatedClauseData}
          rawContent={rawContent}
          clauseData={clauseData}
          parentClauseType={parentClauseType}
          sectionType={sectionType}
          buttonLoading={buttonLoading}
        />
      );
    }
    default:
      return (
        <EditPhrase
          clauseName={clauseName}
          clauseDisplayName={clauseDisplayName}
          savedInsight={savedInsight}
          onClose={onClose}
          id={clauseId}
          updateContract={updateContract}
          updatedClauseData={updatedClauseData}
          rawContent={rawContent}
          clauseData={clauseData}
          parentClauseType={parentClauseType}
          sectionType={sectionType}
          buttonLoading={buttonLoading}
        />
      );
  }
};

export default EditFeature;
